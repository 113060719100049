/* You can add global styles to this file, and also import other style files */

/* GLOBAL STYLES
-------------------------------------------------- */
/* Padding below the footer and lighter body text */
body {
  padding-top: 3rem;
  /*padding-bottom: 3rem;*/
  color: #5a5a5a;
}

.text-danger {
  color: #7f0f40 !important;
}

.btn-danger {
  color: #fff;
  background-color: #7f0f40;
  border-color: #7f0f40;
}